import Glide from '@glidejs/glide';

const featuredProductCarousel = () => {
	const carousel = document.querySelector('.featured-product-carousel .glide');

	if (carousel) {
		new Glide(carousel, {
			type: 'carousel',
			startAt: 0,
			perView: 1,
		}).mount();
	}
}

export default featuredProductCarousel;