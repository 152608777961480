const stickyHeader = () => {
	const header = document.querySelector('.site-header');

	window.addEventListener('scroll', () => {
		if (document.body.scrollTop > 100 || document.documentElement.scrollTop > 100) {
			header.classList.add('is-sticky');
		} else {
			header.classList.remove('is-sticky');
		}
	});
}

export default stickyHeader;