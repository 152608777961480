const toggleOverlay = () => {
	const toggle = document.querySelector('.nav-toggle');
	const overlay = document.querySelector('.overlay-nav');
	// const logo = document.querySelector('.site-header__logo');
	const html = document.getElementsByTagName('html')[0];

	if (toggle) {
		toggle.addEventListener('click', () => {
			if (!overlay.classList.contains('is-open')) {
				overlay.classList.add('is-open');
				toggle.classList.add('is-active');
				html.classList.add('no-scroll');
				// logo.style.opacity = 0;
			} else {
				overlay.classList.remove('is-open');
				toggle.classList.remove('is-active');
				html.classList.remove('no-scroll');
				// logo.removeAttribute('style');
			}
		});
	}
}

export default toggleOverlay;