const openModal = (e) => {
  // console.log('modal test');
  console.log(e.currentTarget.dataset.vacancyHeading);
  console.log(e.currentTarget.dataset.vacancyContent);

  const modal = document.querySelector(".modal");
  const close = modal.querySelector(".modal__close");
  const location = modal.querySelector(".modal__content-location");
  const heading = modal.querySelector(".modal__content-heading");
  const description = modal.querySelector(".modal__content-description");
  const backdrop = document.getElementsByClassName("backdrop");
  const html = document.getElementsByTagName("html")[0];

  // Open modal
  if (!modal.classList.contains("is-open")) {
    modal.classList.add("is-open");
    html.classList.add("no-scroll");
  } else {
    modal.classList.remove("is-open");
    html.classList.remove("no-scroll");
  }

  // Close Modal
  close.addEventListener("click", () => {
    const backdrop = document.querySelector(".backdrop");
    modal.classList.remove("is-open");
    html.classList.remove("no-scroll");
    backdrop.remove();
  });

  // Inject content
  location.innerHTML = e.currentTarget.dataset.vacancyLocation;
  heading.innerHTML = e.currentTarget.dataset.vacancyHeading;
  description.innerHTML = e.currentTarget.dataset.vacancyContent;

  // Backdrop
  if (backdrop.length < 1) {
    const div = document.createElement("div");
    div.classList.add("backdrop");
    document.body.append(div);

    // Close open element if backdrop clicked
    backdrop[0].addEventListener("click", () => {
      modal.classList.remove("is-open");
      html.classList.remove("no-scroll");
      const backdrop = document.querySelector(".backdrop");
      backdrop.remove();
    });
  }
};

const vacancyModal = () => {
  const vacancyCards = document.querySelectorAll(".vacancies__item");

  if (vacancyCards) {
    vacancyCards.forEach((card) => {
      card.addEventListener("click", openModal);
    });
  }
};

export default vacancyModal;
