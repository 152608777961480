const accordions = () => {
	let accordion = document.querySelectorAll('.accordion');

    if (!accordion) return false;

    accordion.forEach(accEl => {
      accEl.addEventListener('click', toggleAccordion, false);
    });

    function toggleAccordion(e) {
      e = this;
      e.classList.toggle('is-active');
      const panel = this.nextElementSibling;

      if (!panel.classList.contains('is-active')) {
        panel.classList.add('is-active');
      } else {
        panel.classList.remove('is-active');
      }
    }
}
    
export default accordions;
    
